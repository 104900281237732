<template>
  <div id="qr">
    <vue-btn-return></vue-btn-return>
    <v-snackbar v-model="isErr" :timeout="t2" top right color="error">
      请输入正确链接后重试
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="isErr = false"> Close </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="isSuccess" :timeout="t1" top right color="success">
      复制成功
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="isSuccess = false"> Close </v-btn>
      </template>
    </v-snackbar>

    <div class="qr__text mt-5" align="center" justify="center">
      <h2>二维码生成工具</h2>
    </div>
    <div class="qr__input mt-12">
      <v-text-field
        label="请输入链接"
        v-model="olink"
        clearable
        outlined
      ></v-text-field>
      <v-btn outlined block color="primary" @click="createQrCode()">
        生成
      </v-btn>
    </div>
    <div
      class="qr__output mt-6 d-flex align-center justify-center"
      v-if="base64img != null"
    >
      <img :src="base64img" alt="" />
      <v-btn class="ml-8" color="success" @click="copyTestingCode()"
        >复制链接</v-btn
      >
      <input type="hidden" id="code" :value="base64img" />
    </div>
  </div>
</template>

<script>
import BtnReturn from "../components/BtnReturn";
export default {
  name: "Qrcode",
  data: () => ({
    olink: "",
    base64img: null,
    selected: "6",
    isErr: false,
    isSuccess: false,
    timer: null,
    timer1: null,
    t1: 5000,
    t2: 3000,
  }),
  components: {
    "vue-btn-return": BtnReturn,
  },
  methods: {
    createQrCode() {
      this.isErr = false;
      clearTimeout(this.timer);
      if (!this.olink) {
        this.base64img = null;
        this.isErr = true;
        this.timer = setTimeout(() => {
          this.isErr = false;
        }, 5000);
        return;
      }
      this.$axios
        .get("/api/tools/qr-code/", {
          params: {
            link: this.olink,
          },
        })
        .then((res) => {
          if (res.data.img) {
            this.base64img = res.data.img;
          }
        });
    },
    copyTestingCode() {
      let testingCodeToCopy = document.querySelector("#code");
      testingCodeToCopy.setAttribute("type", "text"); // 不是 hidden 才能複製
      testingCodeToCopy.select();
      clearTimeout(this.timer1);
      try {
        var successful = document.execCommand("copy");
        this.isSuccess = successful ? true : false;

        this.timer1 = setTimeout(() => {
          this.isSuccess = false;
        }, 2000);
      } catch (err) {
        alert("Oops, unable to copy");
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
};
</script>

<style scoped>
#qr {
  max-width: 600px;
  margin: 0 auto;
}
</style>